.elementor-image-carousel .slick-list {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

@media (max-width: 1500px) {
    .slick-slide {
        padding: 1em;
    }
}

@media (max-width: 1500px) {
    .elementor-slick-slider {
        .slick-prev {
            left: 0;
        }

        .slick-next {
            right: 0;
        }
    }

    [dir="rtl"] .elementor-slick-slider {
        .slick-prev {
            left: auto;
            right: 0;
        }

        .slick-next {
            left: 0;
            right: auto;
        }
    }
}